import React from 'react';
import PropTypes from 'prop-types';
import FireEvent from '../../utils/FireEvent';
import { useDispatch } from 'react-redux';
import * as API from '../../api';
import bridge from '@vkontakte/vk-bridge';

import {Alert, CardScroll} from '@vkontakte/vkui';

import BasicContainer from '../../components/BasicContainer';
import SliderCard from '../../components/SliderCard';

import Icon28Users3Outline from '@vkontakte/icons/dist/28/users_3_outline';
import Icon28StoryOutline from '@vkontakte/icons/dist/28/story_outline';
import {setPopout} from "../../store/navigation/actions";

import './index.scss'

const BusinessFeatures = ({ id }) => {
    const dispatch = useDispatch();
    const iconColor = {
        start: '#50c750',
        end: '#32b332'
    };

    const subscribe = () => {
        bridge.send("VKWebAppJoinGroup", { "group_id": 166562603 })
            .then(({ result }) => {
                if (result) {
                    return dispatch(setPopout(
                        <Alert
                            actions={[
                                {
                                    title: 'Посмотреть',
                                    mode: 'cancel',
                                    action: () => {
                                        FireEvent("https://vk.com/club166562603");
                                        dispatch(setPopout(null));
                                    },
                                },
                                {
                                    title: 'Закрыть',
                                    autoclose: true,
                                    mode: 'cancel'
                                }
                            ]}
                            onClose={() => dispatch(setPopout(null))}
                        >
                            <h2>Поздравляем!</h2>
                            <p>Вы в сообществе VK Mini Apps</p>
                        </Alert>
                    ));
                } else {
                    return dispatch(setPopout(
                        <Alert
                            actions={[
                                {
                                    title: 'Закрыть',
                                    autoclose: true,
                                    mode: 'cancel'
                                }
                            ]}
                            onClose={() => dispatch(setPopout(null))}
                        >
                            <h2>Упс...</h2>
                            <p>Что-то пошло не так. Попробуйте еще раз</p>
                        </Alert>
                    ));
                }
            })
            .catch(() => {
                dispatch(setPopout(
                    <Alert
                        actions={[
                            {
                                title: 'Закрыть',
                                autoclose: true,
                                mode: 'cancel'
                            }
                        ]}
                        onClose={() => dispatch(setPopout(null))}
                    >
                        <h2>Упс...</h2>
                        <p>Что-то пошло не так. Попробуйте еще раз</p>
                    </Alert>
                ));
            });
    };

    const sendNotify = () => {
        API.sendNotification()
            .then(() => {
                dispatch(setPopout(
                    <Alert
                        actions={[
                            {
                                title: 'Закрыть',
                                autoclose: true,
                                mode: 'cancel'
                            }
                        ]}
                        onClose={() => dispatch(setPopout(null))}
                    >
                        <h2>Поздравляем!</h2>
                        <p>Тестовое уведомление успешно отправлено!</p>
                    </Alert>
                ));
            })
            .catch((error) => {
                if (error === "server") {
                    return dispatch(setPopout(
                        <Alert
                            actions={[
                                {
                                    title: 'Закрыть',
                                    autoclose: true,
                                    mode: 'cancel'
                                }
                            ]}
                            onClose={() => dispatch(setPopout(null))}
                        >
                            <h2>Упс...</h2>
                            <p>Технические проблемы на стороне сервера</p>
                        </Alert>
                    ));
                }

                return dispatch(setPopout(
                    <Alert
                        actions={[
                            {
                                title: 'Закрыть',
                                autoclose: true,
                                mode: 'cancel'
                            }
                        ]}
                        onClose={() => dispatch(setPopout(null))}
                    >
                        <h2>Упс...</h2>
                        <p>Нам нужно ваше разрешение на отправку уведомлений</p>
                    </Alert>
                ));
            });
    };

    const openStoryEditor = () => {
        bridge.send("VKWebAppShowStoryBox", {
            background_type: "none",
            attachment: {
                text: 'open',
                type: 'url',
                url: `https://vk.com/app${window.vkapp_config && window.vkapp_config.vk_app_id}`
            },
            stickers: [
                {
                    sticker_type: "renderable",
                    sticker: {
                        content_type: "image",
                        url: `https://${window.location.host}/sticker.png`,
                        original_width: 280,
                        original_height: 130,
                        transform: {
                            relation_width: 1
                        },
                        clickable_zones: [
                            {
                                action_type: "link",
                                action: {
                                    link: `https://vk.com/app${window.vkapp_config && window.vkapp_config.vk_app_id}`,
                                    tooltip_text_key: "open"
                                }
                            }
                        ]
                    }
                }
            ]
        });
    };

    return (
        <BasicContainer
            id={id}
            title={<>Полезно<br/>для бизнеса</>}
            prevPanel="user-features"
            slidesAmount={2}
            buttons={[
                {
                    text: 'А как монетизировать?',
                    nextPanel: 'monetization',
                    mode: 'secondary',
                }
            ]}
        >
            <CardScroll className="business-features__slider">
                <SliderCard
                    iconColor={iconColor}
                    title={<>Связь с аудиторией</>}
                    text={<>Одной кнопкой можно отправить <br className="iphone5s"/>
                        клиенту push-уведомление или <br className="iphone5s"/>
                        предложить подписаться <br className="iphone5s"/>
                        на сообщество</>}
                    icon={<Icon28Users3Outline/>}
                    buttons={[{
                        text: "Отправить уведомление",
                        handler: sendNotify,
                    }, {
                        text: "Подписаться на страницу",
                        handler: subscribe,
                    }]}
                />
                <SliderCard
                    iconColor={iconColor}
                    title={<>Уникальные<br/>возможности</>}
                    text={<>Истории с интерактивными<br/>
                        стикерами, которыми активно<br/>
                        делятся
                        </>}
                    icon={<Icon28StoryOutline/>}
                    buttons={[{
                        text: "Опубликовать историю",
                        handler: openStoryEditor
                    }]}
                />
            </CardScroll>
        </BasicContainer>
    );
};

BusinessFeatures.propTypes = {
    id: PropTypes.string.isRequired,
};
export default BusinessFeatures;
