import React from 'react';
import PropTypes from 'prop-types';

import BasicContainer from '../../components/BasicContainer';

import './index.scss'


const Stats = ({ id }) => {
    return (
        <BasicContainer
            centered
            id={id}
            prevPanel="welcome"
            buttons={[
                {
                    text: 'Хочу знать больше',
                    nextPanel: 'quick-access',
                }
            ]}
        >
            <p className={"stats__text"}>
                Мини-приложения<br/>
                доступны всей<br/>
                аудитории ВКонтакте,<br/>
                а это
            </p>
            <div className={"stats__main"}>97 млн</div>
            <p className={"stats__text"}>пользователей в месяц</p>
        </BasicContainer>
    );
};

Stats.propTypes = {
    id: PropTypes.string.isRequired,
};

export default Stats;
