import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { View, ModalRoot } from '@vkontakte/vkui';

import Welcome from './Welcome';
import Introduction from './Introduction/';
import Stats from './Stats/';
import QuickAccess from './QuickAccess';
import UserFeatures from './UserFeatures';
import BusinessFeatures from './BusinessFeatures/';
import Flexibility from './Flexibility/';
import SimpleDevelop from './SimpleDevelop';
import Monetization from './Monetization/';

import UserInfoModal from './UserInfoModal/';
import UserFriendsModal from './UserFriendsModal/';
import GeoModal from './GeoModal/';
import VkPayModal from './VkPayModal';
import {setModal} from '../store/navigation/actions';

import './index.scss';

const App = () => {
    const dispatch = useDispatch();
    const activePanel = useSelector((store) => store.navigation.activePanel);
    const activeModal = useSelector((store) => store.navigation.activeModal);
    const popout = useSelector((store) => store.navigation.popout);

    const modals = (
        <ModalRoot
            activeModal={activeModal}
            onClose={() => dispatch(setModal(null))}
        >
            <UserInfoModal id="user-info" />
            <UserFriendsModal id="user-friends-list"/>
            <GeoModal id="user-geo" />
            <VkPayModal id="vk-pay"/>
        </ModalRoot>
    );

    return (
        <View activePanel={activePanel} modal={modals} popout={popout}>
            <Welcome id="welcome" />
            <Stats id="stats"/>
            <QuickAccess id="quick-access"/>
            <UserFeatures id="user-features"/>
            <BusinessFeatures id="business-features"/>
            <Monetization id="monetization"/>
            <SimpleDevelop id="simple-develop"/>
            <Flexibility id="flexibility"/>
            <Introduction id="introduction"/>
        </View>
    );
};

export default App;
