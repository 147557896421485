import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import { ModalCard, ModalPageHeader } from '@vkontakte/vkui';

import { setModal } from '../../store/navigation/actions';

import './index.scss'

const GeoModal = ({ id }) => {
    const dispatch = useDispatch();
    const onClose = () => dispatch(setModal(null));
    const location = useSelector((store) => store.profile.location);

    return (
        <div className="geo-modal">
            <ModalCard
                id={id}
                header={
                    <ModalPageHeader>
                        Ты на карте
                    </ModalPageHeader>
                }
                actions={[{
                    title: 'Ясно',
                    mode: 'secondary',
                    action: onClose,
                }]}
                actionsLayout="vertical"
                onClose={onClose}
            >
                <div className="geo-modal__wrapper">
                    <YMaps>
                        <Map defaultState={{ center: [location.long, location.alt], zoom: 9 }}>
                            <Placemark geometry={[location.long, location.alt]} />
                        </Map>
                    </YMaps>
                </div>
            </ModalCard>
        </div>
    );
};

export default GeoModal;
