import React from 'react';
import PropTypes from 'prop-types';
import {
    ModalCard,
    ModalPageHeader,
    PromoBanner,
    Progress
} from '@vkontakte/vkui';
import { useDispatch, useSelector } from 'react-redux';
import {setModal, setProgressValue} from '../../store/navigation/actions';

import './index.scss';

import { goPanel} from '../../store/navigation/actions';

const VkPayModal = ({id}) => {
    const dispatch = useDispatch();
    const progressBarValue = useSelector((store) => store.navigation.progressBarValue);

    const onClose = () => {
        dispatch(setModal(null));
    };

    const promoBannerProps = {
        title: 'Заголовок',
        domain: 'vk.com',
        ctaText: 'Перейти',
        advertisingLabel: 'Реклама',
        iconLink: 'https://sun9-7.userapi.com/c846420/v846420985/1526c3/ISX7VF8NjZk.jpg',
        description: 'Описание рекламы',
        ageRestriction: 14
    };

    return (
        <div className="vk-pay-modal">
            <ModalCard
                id={id}
                onClose={onClose}
                header={
                    <ModalPageHeader>
                        <span>
                            Встраивайте рекламу<br/>
                            прямо в мини-приложение
                        </span>
                    </ModalPageHeader>
                }
                actions={[{
                    title: 'Это удобно',
                    mode: 'primary',
                    action: () => {
                        setTimeout(() => {
                            dispatch(setModal(null));
                            dispatch(goPanel('simple-develop'));
                            dispatch(setProgressValue(progressBarValue + 11.111));
                        }, 200)
                    }
                }]}
            >
                <PromoBanner
                    bannerData={promoBannerProps}
                    onClose={onClose}
                />
                <Progress value={progressBarValue} className="basic-container__progress" />
            </ModalCard>
        </div>

    )
};

VkPayModal.propTypes = {
    id: PropTypes.string.isRequired
};

export default VkPayModal;
