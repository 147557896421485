import React from 'react';

const VkPayLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="19" viewBox="0 0 56 19">
            <path fill="#FFF" d="M12.51-.066c5.362 0 6.639 1.276 6.639 6.632v5.868c0 5.356-1.277 6.632-6.638 6.632H6.638C1.277 19.066 0 17.79 0 12.434V6.566C0 1.21 1.277-.066 6.638-.066h5.873zM48.77 4.737l2.435 6.35 2.23-6.35h2.528s-3.69 9.536-3.915 10.06c-.937 2.323-1.799 3.166-3.372 3.166-.431 0-.862-.094-.862-.094v-2.023c1.068 0 1.611-.524 1.91-1.255l.207-.543-3.69-9.31h2.529zm-17.492-.093c2.435 0 4.552 2.117 4.552 4.964 0 2.866-2.117 4.983-4.552 4.983-1.592 0-2.548-.955-2.548-.955v4.233h-2.435V4.737h1.799l.43.956s1.05-1.05 2.754-1.05zm10.179 0c2.21 0 3.803 1.573 3.803 3.803v6.032H43.46l-.412-.956s-1.068 1.068-2.754 1.068c-2.116 0-3.278-1.161-3.278-2.754 0-1.91 1.368-3.278 5.807-3.278 0-1.068-.636-1.705-1.573-1.705-1.274 0-1.499 1.068-1.499 1.068h-2.435c.225-1.798 1.705-3.278 4.14-3.278zM5.186 5.913H3.79c-.399 0-.479.187-.479.394 0 .37.474 2.202 2.204 4.626 1.154 1.655 2.78 2.553 4.259 2.553.887 0 .997-.2.997-.543v-1.25c0-.4.084-.479.365-.479.207 0 .562.103 1.39.902.948.945 1.104 1.37 1.636 1.37h1.396c.4 0 .599-.2.484-.593-.126-.392-.578-.96-1.178-1.635-.325-.384-.814-.798-.961-1.005-.208-.266-.148-.384 0-.62 0 0 1.7-2.395 1.878-3.207.089-.296 0-.513-.422-.513h-1.396c-.355 0-.519.187-.608.394 0 0-.71 1.73-1.716 2.853-.325.325-.473.428-.65.428-.09 0-.218-.103-.218-.399V6.426c0-.355-.103-.513-.399-.513H8.178c-.222 0-.355.164-.355.32 0 .337.503.414.555 1.36v2.054c0 .45-.082.533-.26.533-.473 0-1.624-1.737-2.307-3.725-.134-.386-.268-.542-.625-.542zm37.637 4.332c-2.847 0-3.39.524-3.39 1.367 0 .544.543 1.068 1.274 1.068 1.273 0 2.116-.843 2.116-2.004zM30.96 6.967c-1.387 0-2.436 1.049-2.436 2.641s1.05 2.641 2.436 2.641c1.367 0 2.435-1.049 2.435-2.641s-1.068-2.641-2.435-2.641z"/>
        </svg>
    )
};

export default VkPayLogo;
