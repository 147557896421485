import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Panel, PanelHeader, PanelHeaderBack, FixedLayout, Div, Button, Progress } from '@vkontakte/vkui';
import './index.scss';

import { goPanel, setProgressValue } from '../../store/navigation/actions';
import blue from '../../assets/blue.png';
import green from '../../assets/green.png';
import sunny from '../../assets/sunny.png';
import red from '../../assets/red.png';
import running from '../../assets/running.png';

const BasicContainer = ({ id, title, buttons, prevPanel, children, centered }) => {
    const dispatch = useDispatch();
    const progressBarValue = useSelector((store) => store.navigation.progressBarValue);
    const isHide = useSelector((store) => store.navigation.isHide);

    const stepNextPanel = (nextPanel) => {
        dispatch(goPanel(nextPanel));
        dispatch(setProgressValue(progressBarValue + 11.111));
    };

    const stepPrevPanel = () => {
        dispatch(goPanel(prevPanel));
        dispatch(setProgressValue(progressBarValue - 11.111));
    };

    useEffect(() => {
        const scrollContainers = document.querySelectorAll('.basic-container .HorizontalScroll__in');
        const scrollContainer = scrollContainers[scrollContainers.length - 1];
        if (!scrollContainer) {
            return;
        }

        const scrollWrapper = scrollContainer.querySelector('.basic-container .CardScroll__in');
        scrollWrapper.style.transition = 'transform 0.4s ease-in-out';

        const timeoutStartId = setTimeout(() => {
            scrollWrapper.style.transform = `translateX(-20px)`;
        }, 1000);

        const timeoutEndId = setTimeout(() => {
            scrollWrapper.style.transform = `none`;
        }, 1500);

        return () => {
            clearTimeout(timeoutStartId);
            clearTimeout(timeoutEndId);
        }

    }, []);

    return (
        <Panel
            id={id}
            centered={centered}
            separator={false}
            className="basic-container"
        >
            <PanelHeader
                left={
                    prevPanel && <PanelHeaderBack onClick={stepPrevPanel} style={{color: "#000"}} />
                }
            />
            <div className={`basic-container__container ${centered && 'basic-container__container-centered'}`}>
                <div className="basic-container__bg">
                    <img className="basic-container__bg-1" src={red} width={45} height={45} alt=""/>
                    <img className="basic-container__bg-2" src={blue} width={60} height={60} alt=""/>
                    <img className="basic-container__bg-3" src={sunny} width={100} height={100} alt=""/>
                    <img className="basic-container__bg-4" src={green} width={55} height={55} alt=""/>
                    <img className="basic-container__bg-5" src={running} width={100} height={100} alt=""/>
                </div>
                {title && (
                    <div className="basic-container__header">
                        {title}
                    </div>
                )}
                {children}
            </div>
            {!isHide && <FixedLayout vertical="bottom">
                <Div className="basic-container__footer">
                    <div className="basic-container__buttons">
                        {buttons.map(({ text, handler = null, nextPanel, mode }) => (
                            <Button
                                key={text}
                                onClick={nextPanel ? () => stepNextPanel(nextPanel) : handler}
                                size="xl"
                                className={`basic-container__button ${mode && ('basic-container__button-' + mode)}`}
                            >
                                {text}
                            </Button>
                        ))}
                    </div>
                    <Progress value={progressBarValue} className="basic-container__progress" />
                </Div>
            </FixedLayout>}
        </Panel>
    );
};

BasicContainer.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.any,
    buttons: PropTypes.any,
    children: PropTypes.any,
    prevPanel: PropTypes.string,
};

BasicContainer.defaultProps = {
    buttons: [],
    slidesAmount: 1,
};

export default BasicContainer;
