import React from 'react';
import {Avatar, Button, Card, CardScroll, Cell} from '@vkontakte/vkui';
import BasicContainer from "../../components/BasicContainer";

import './index.scss';

import albumPoster1 from '../../assets/album_cover_flower_boy.png';
import albumPoster2 from '../../assets/album_cover_ginger.jpg';
import albumPoster3 from '../../assets/album_cover_ballads.png';
import bridge from "@vkontakte/vk-bridge";

const SimpleDevelop = ({id}) => {
    return (
        <BasicContainer
            id={id}
            title={<>Простая<br/> разработка</>}
            prevPanel="monetization"
            buttons={[
                {
                    text: 'Ничего себе',
                    nextPanel: 'flexibility',
                }
            ]}
        >
            <div className="simple-develop__card">
                <Card
                    mode="shadow"
                >
                    <div className="simple-develop__card-title">
                        Открытый набор<br/>инструментов
                    </div>
                    <div className="simple-develop__card-text">
                        Библиотека UI-компонентов,<br/>
                        которую Вы можете использовать
                    </div>
                    <div className="simple-develop__albums">
                        <CardScroll>
                            <Card className="simple-develop__album">
                                <Cell
                                    size="l"
                                    before={
                                        <Avatar mode="image" size={135} src={albumPoster1}/>
                                    }
                                    description="Flower Boy"
                                    bottomContent={
                                        <Button
                                            mode="outline"
                                            size="m"
                                            onClick={() => bridge.send("VKWebAppTapticNotificationOccurred", { "type": "success" })}
                                        >
                                            Кнопка
                                        </Button>
                                    }
                                >
                                    Tyler, The Creator
                                </Cell>
                            </Card>
                            <Card className="simple-develop__album">
                                <Cell size="l"
                                      before={
                                          <Avatar mode="image" size={135} src={albumPoster2}/>
                                      }
                                      description="Ginger"
                                      bottomContent={
                                          <Button
                                              mode="outline"
                                              size="m"
                                              onClick={() => bridge.send("VKWebAppTapticNotificationOccurred", { "type": "success" })}
                                          >
                                              Кнопка
                                          </Button>
                                      }
                                >
                                    Brockhampton
                                </Cell>
                            </Card>
                            <Card className="simple-develop__album">
                                <Cell
                                    size="l"
                                    before={
                                        <Avatar mode="image" size={135} src={albumPoster3}/>
                                    }
                                    description="Ballads 1"
                                    bottomContent={
                                        <Button
                                            mode="outline"
                                            size="m"
                                            onClick={() => bridge.send("VKWebAppTapticNotificationOccurred", { "type": "success" })}
                                        >
                                            Кнопка
                                        </Button>
                                    }
                                >
                                    Joji
                                </Cell>
                            </Card>
                        </CardScroll>
                    </div>

                </Card>
            </div>
        </BasicContainer>
    )
};

export default SimpleDevelop;
