import 'core-js/features/map';
import 'core-js/features/set';

import React from 'react';
import ReactDOM from 'react-dom';
import bridge from '@vkontakte/vk-bridge';

import App from './containers';
import '@vkontakte/vkui/dist/vkui.css';

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './store';
import * as API from './api';

import { loadProfile } from './store/profile/actions';

// Global config
const search = window.location.search.slice(1, window.location.search.length).replace(/&amp;/g, '&');
window.vkapp_config = search.split("&").reduce((a, param) => {
    const data = param.split("=");
    if (data[0] === "vk_app_id") a[data[0]] = Number(data[1]);
    else a[data[0]] = data[1];
    return a;
}, {});

export const store = createStore(rootReducer);
API.getProfile().then((profile) => store.dispatch(loadProfile(profile)));

// Init VK Mini App
bridge.send('VKWebAppInit');

bridge.send('VKWebAppSetViewSettings', {'status_bar_style': 'dark', 'action_bar_color': '#fff'});

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
