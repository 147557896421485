import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {useDispatch} from 'react-redux';
import {setModal, setPopout} from '../../store/navigation/actions';

import * as API from '../../api';

import BasicContainer from '../../components/BasicContainer';
import {Alert, Div} from '@vkontakte/vkui';

import SliderCard from '../../components/SliderCard';
import VkPayLogo from '../../components/VkPayIcon';

import './index.scss';

import {getRandomArrayItem} from '../../utils/getRandomItemFromArray';

const Monetization = ({ id }) => {
    const [inputAmount, setInputAmount] = useState('10');
    const dispatch = useDispatch();

    const iconColor = {
        start: "#ffb73d",
        end: "#ffa000"
    };

    const merchants = [618154, 829719, 694685, 754928, 741876, 949288];

    const sendDonation = () => {
        if (inputAmount) {
            API.openVKPay(inputAmount, getRandomArrayItem(merchants))
                .catch(() => {
                    dispatch(setPopout(
                        <Alert
                            actions={[{
                                title: 'Закрыть',
                                autoclose: true,
                                mode: 'cancel'
                            }]}
                            onClose={() => dispatch(setPopout(null))}
                        >
                            <h2>Упс...</h2>
                            <p>Что-то пошло не так, попробуйте позже</p>
                        </Alert>
                    ));
                });
        }
    };

    return (
        <BasicContainer
            id={id}
            title={<>Простая<br/>монетизация</>}
            prevPanel="business-features"
            buttons={[
                {
                    text: 'Приятно',
                    handler: () => dispatch(setModal('vk-pay')),
                    mode: 'secondary',
                }
            ]}
        >
            <Div className="monetization">
                <SliderCard
                    iconColor={iconColor}
                    title={<>VK Pay</>}
                    text={<>Можно не подключать оплату <br className="iphone5s"/>
                        картами — VK Pay работает прямо <br className="iphone5s"/>
                        в мини-приложении<br className="iphone5s"/>
                        </>}
                    icon={<VkPayLogo/>}
                    form
                    inputAmount={inputAmount}
                    setInputAmount={setInputAmount}
                    buttons={[{
                        text: 'Отправить',
                        handler: () => sendDonation(),
                    }]}
                />
            </Div>
        </BasicContainer>
    );
};

Monetization.propTypes = {
    id: PropTypes.string.isRequired,
};

export default Monetization;
