import * as types from './actionTypes';

export const loadProfile = (profile = {}) => {
    return {
        type: types.PROFILE_LOAD,
        profile,
    };
};

export const loadFriends = (friends = []) => {
    return {
        type: types.PROFILE_LOAD_FRIENDS,
        friends,
    };
};

export const loadLocation = (long = 1, alt = 1) => {
    return {
        type: types.PROFILE_LOAD_LOCATION,
        location: {
            long,
            alt,
        }
    };
};

export const loadPhone = (phone = null) => {
    return {
        type: types.PROFILE_LOAD_PHONE,
        phone,
    };
};
