import React from 'react';
import * as API from '../../api';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import BasicContainer from '../../components/BasicContainer';
import { CardScroll, Alert } from '@vkontakte/vkui';
import SliderCard from '../../components/SliderCard';

import Icon28Profile from '@vkontakte/icons/dist/28/profile';
import Icon28PlaceOutline from '@vkontakte/icons/dist/28/place_outline';
import Icon28UsersOutline from '@vkontakte/icons/dist/28/users_outline';

import './index.scss';

import { setModal, setPopout } from '../../store/navigation/actions';
import { loadFriends, loadLocation, loadPhone } from '../../store/profile/actions';


const UserFeatures = ({ id }) => {

    const dispatch = useDispatch();
    const iconColor = {
        start: '#70b2ff',
        end: '#5c9ce6'
    };

    const openProfile = () => {
        API.getPhone()
            .then((phone) => {
                dispatch(loadPhone(phone));
                dispatch(setModal("user-info"));
            })
            .catch(() => {
                dispatch(setPopout(
                    <Alert
                        actions={[{
                            title: 'Закрыть',
                            autoclose: true,
                            mode: 'cancel'
                        }]}
                        onClose={() => dispatch(setPopout(null))}
                    >
                        <h2>Упс...</h2>
                        <p>Нам требуется разрешение на чтение вашего номера телефона</p>
                    </Alert>
                ));
            });
    };

    const openFriends = () => {
        API.getFriends()
            .then((friends) => {
                dispatch(loadFriends(friends));
                dispatch(setModal("user-friends-list"));
            })
            .catch(() => {
                dispatch(setPopout(
                    <Alert
                        actions={[{
                            title: 'Закрыть',
                            autoclose: true,
                            mode: 'cancel'
                        }]}
                        onClose={() => dispatch(setPopout(null))}
                    >
                        <h2>Упс...</h2>
                        <p>Нам требуется разрешение на чтение списка ваших друзей</p>
                    </Alert>
                ));
            });
    };

    const openMap = () => {
        API.getLocation()
            .then((coords) => {
                dispatch(loadLocation(...coords));
                dispatch(setModal("user-geo"));
            })
            .catch((e) => {
                if (e === "not-available") {
                    return dispatch(setPopout(
                        <Alert
                            actions={[{
                                title: 'Закрыть',
                                autoclose: true,
                                mode: 'cancel'
                            }]}
                            onClose={() => dispatch(setPopout(null))}
                        >
                            <h2>Упс...</h2>
                            <p>Разрешите приложению VK доступ к геолокации в настройках</p>
                        </Alert>
                    ));
                }

                return dispatch(setPopout(
                    <Alert
                        actions={[{
                            title: 'Закрыть',
                            autoclose: true,
                            mode: 'cancel'
                        }]}
                        onClose={() => dispatch(setPopout(null))}
                    >
                        <h2>Упс...</h2>
                        <p>Нам требуется разрешение для отображения вашей геолокации</p>
                    </Alert>
                ));
            })
    };

    return (
        <BasicContainer
            slidesAmount={3}
            id={id}
            title={<>Удобно<br/>для пользователя</>}
            prevPanel="quick-access"
            buttons={[
                {
                    text: 'Зачем это бизнесу?',
                    nextPanel: 'business-features',
                    mode: 'secondary',
                }
            ]}
        >
            <CardScroll>
                <SliderCard
                    iconColor={iconColor}
                    title={<>Не надо<br/>регистрироваться</>}
                    text={<>Нужен лишь доступ<br/>
                        к профилю —<br/>
                        вся информация загрузится<br/>
                        автоматически
                    </>}
                    icon={<Icon28Profile/>}
                    buttons={[{
                        text: 'Открыть профиль',
                        handler: openProfile,
                    }]}
                />
                <SliderCard
                    iconColor={iconColor}
                    title={<>Не надо<br/> вводить адрес</>}
                    text={<>Нужно только разрешить доступ<br/> к геолокации</>}
                    icon={<Icon28PlaceOutline/>}
                    buttons={[{
                        text: 'Открыть карту',
                        handler: openMap,
                    }]}
                />
                <SliderCard
                    iconColor={iconColor}
                    title={<>Легко<br/>делиться</>}
                    text={<>Всегда можно похвастаться<br/>
                        чем-то перед друзьями
                    </>}
                    icon={<Icon28UsersOutline/>}
                    buttons={[{
                        text: 'Показать друзей',
                        handler: openFriends
                    }]}
                />
            </CardScroll>
        </BasicContainer>
    );
};

UserFeatures.propTypes = {
    id: PropTypes.string.isRequired,
};

export default UserFeatures;
