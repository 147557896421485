import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import bridge from '@vkontakte/vk-bridge';
import './index.scss';

import BasicContainer from '../../components/BasicContainer';
import { CardScroll, Separator, Card, Alert } from '@vkontakte/vkui';
import SliderCard from '../../components/SliderCard';

import Icon28MagicWandOutline from '@vkontakte/icons/dist/28/magic_wand_outline';

import { setPopout } from "../../store/navigation/actions";


const Flexibility = ({ id }) => {
    const dispatch = useDispatch();
    const iconColor = {
        start: '#65c2c2',
        end: '#86d1d1'
    };

    const enableLight = () => {
        bridge.send("VKWebAppFlashGetInfo", {})
            .then(({ is_available, level }) => {
                if (!is_available) {
                    dispatch(setPopout(
                        <Alert
                            actions={[{
                                title: 'Закрыть',
                                autoclose: true,
                                mode: 'cancel'
                            }]}
                            onClose={() => dispatch(setPopout(null))}
                        >
                            <h2>Упс...</h2>
                            <p>Кажется, у вас нет фонарика :(</p>
                        </Alert>
                    ));
                }

                if (level > 0) {
                    bridge.send("VKWebAppFlashSetLevel", { "level": 0 });
                } else {
                    bridge.send("VKWebAppFlashSetLevel", { "level": 1 });
                }

                bridge.send("VKWebAppTapticNotificationOccurred", { "type": "success" });
            })
            .catch(() => {

            })
    };

    return (
        <BasicContainer
            id={id}
            title={<>Невероятная<br/>гибкость</>}
            prevPanel="simple-develop"
            slidesAmount={2}
            buttons={[
                {
                    text: 'Как это создавать?',
                    nextPanel: 'introduction',
                }
            ]}
        >
            <CardScroll className="flexibility">
                <SliderCard
                    iconColor={iconColor}
                    title="Функции устройства"
                    text={<>Мини-приложения могут<br/>
                        управлять функциями телефона
                    </>}
                    icon={<Icon28MagicWandOutline/>}
                    buttons={[{
                        text: 'Камера ВКонтакте',
                        handler: () => bridge.send("VKWebAppOpenCodeReader", {}),
                    }, {
                        text: 'Фонарик',
                        handler: enableLight
                    }]}
                />
                <Card className="flexibility__slide" mode="shadow">
                    <div className="flexibility__slide--upper">
                        <span className="flexibility__slide-title">Хранилище</span>
                        <span className="flexibility__slide-text">
                            Мы предоставим место<br/>
                            для хранения данных Вашего<br/>
                            мини-приложения
                        </span>
                    </div>
                    <Separator wide />
                    <div className="flexibility__slide--bottom">
                        <span className="flexibility__slide-title">Все платформы</span>
                        <span className="flexibility__slide-text">
                            Мини-приложения работают<br/>
                            и на мобильных<br/>
                            устройствах, и на сайте
                        </span>
                    </div>
                </Card>
            </CardScroll>
        </BasicContainer>
    );
};

Flexibility.propTypes = {
    id: PropTypes.string.isRequired,
};

export default Flexibility;
