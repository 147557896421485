import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {ModalCard, ModalPageHeader, Separator, List, Cell, Avatar} from '@vkontakte/vkui';
import {setModal} from '../../store/navigation/actions';

import './index.scss';

const UserFriendsModal = ({id}) => {

    const dispatch = useDispatch();
    const onClose = () => dispatch(setModal(null));
    const friends = useSelector((store) => store.profile.friends);

    return (
        <div className="user-friends-list">
            <ModalCard
                id={id}
                header={
                    <ModalPageHeader>
                        Твои друзья
                    </ModalPageHeader>
                }
                onClose={onClose}
                actions={[{
                    title: 'Ясно',
                    mode: 'secondary',
                    action: onClose
                }]}
                actionsLayout="vertical"
            >
                <Separator/>
                <List>
                    {friends && friends.map(({id, photo_200, first_name, last_name}) => (
                        <Cell
                            key={id}
                            before={
                                <Avatar src={photo_200} size={48}/>
                            }
                        >
                            {first_name} {last_name}
                        </Cell>
                    ))}
                </List>
            </ModalCard>
        </div>
    )
};

export default UserFriendsModal;
