import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import BasicContainer from '../components/BasicContainer';

import Logo from '../assets/vk-product-logo.svg';


const Welcome = ({ id }) => {
    const profile = useSelector((store) => store.profile.profile);

    return (
        <BasicContainer
            centered
            id={id}
            buttons={[
                {
                    text: 'Интересно',
                    nextPanel: 'stats'
                }
            ]}
        >
            <img src={Logo} alt="" />
            <p style={{ fontSize: 19, lineHeight: 1.26, padding: '0 24px' }}>
                {profile.first_name}, это мини-приложение ВКонтакте.<br/>
                Сейчас мы объясним, что это такое и зачем нужно.
            </p>
        </BasicContainer>
    );
};

Welcome.propTypes = {
    id: PropTypes.string.isRequired,
};

export default Welcome;
