import * as types from './actionTypes';

const initialState = {
    activePanel: "welcome",
    activeModal: null,
    popout: null,
    progressBarValue: 11.111,
    isHide: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.NAVIGATION_GO_PANEL: {
            const { nextPanel } = action;
            return {
                ...state,
                activePanel: nextPanel,
            };
        }

        case types.NAVIGATION_SET_MODAL: {
            const { modal } = action;
            return {
                ...state,
                activeModal: modal,
            };
        }

        case types.NAVIGATION_SET_POPOUT: {
            const { popout } = action;
            return {
                ...state,
                popout,
            };
        }

        case types.NAVIGATION_SET_PROGRESS_VALUE: {
            const { progressBarValue } = action;
            return {
                ...state,
                progressBarValue,
            }
        }

        case types.NAVIGATION_HIDE: {
            const { isHide } = action;
            return {
                ...state,
                isHide
            }
        }

        default: return state;
    }
};
