import * as types from './actionTypes';

const initialState = {
    loaded: false,
    profile: {},
    friends: [],
    phone: null,
    location: {
        long: 55.751574,
        alt: 37.573856,
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case types.PROFILE_LOAD: {
            const { profile } = action;
            return {
                ...state,
                loaded: true,
                profile,
            };
        }

        case types.PROFILE_LOAD_FRIENDS: {
            const { friends } = action;
            return {
                ...state,
                friends,
            };
        }

        case types.PROFILE_LOAD_LOCATION: {
            const { location } = action;
            return {
                ...state,
                location,
            };
        }

        case types.PROFILE_LOAD_PHONE: {
            const { phone } = action;
            return {
                ...state,
                phone,
            };
        }

        default: return state;
    }
};
