import React from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { ModalCard, ModalPageHeader, Avatar, InfoRow, Cell, Separator } from '@vkontakte/vkui';

import { setModal } from '../../store/navigation/actions';

import './index.scss';

import DateTransform from '../../utils/DateTransform';
import PhoneNumberTransform from '../../utils/PhoneNumberTransform';

const UserInfoModal = ({ id }) => {
    const dispatch = useDispatch();
    const profile = useSelector((store) => store.profile.profile);
    const phone = useSelector((store) => store.profile.phone);
    const onClose = () => dispatch(setModal(null));

    return (
        <div className="user-info">
            <ModalCard
                id={id}
                header={
                    <ModalPageHeader>
                        Твой профиль
                    </ModalPageHeader>
                }
                onClose={onClose}
                actions={[{
                    title: 'Ясно',
                    mode: 'secondary',
                    action: onClose
                }]}
                actionsLayout="vertical"
            >
                <Separator />
                <Cell
                    before={
                        <Avatar src={profile.photo_200} size={48} />
                    }
                >
                    {profile.first_name} {profile.last_name}
                </Cell>

                <InfoRow header="Дата рождения">
                    {DateTransform(profile.bdate)}
                </InfoRow>

                <InfoRow header="Номер телефона">
                    {PhoneNumberTransform(phone)}
                </InfoRow>
            </ModalCard>
        </div>
    );
};

UserInfoModal.propTypes = {
    id: PropTypes.string.isRequired,
};

export default UserInfoModal;
