import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Card, Avatar, FormLayout, FormLayoutGroup, Input} from '@vkontakte/vkui';

import './index.scss';
import {useDispatch} from "react-redux";
import {hideNavigation} from "../../store/navigation/actions";

const SliderCard = ({icon, title, text, buttons, iconColor, form, inputAmount, setInputAmount}) => {

    const dispatch = useDispatch();

    const [isError, setIsError] = useState(false);

    return (
        <Card className="slider-card" mode="shadow">
            <div className="slider-card__wrapper">
                <Avatar
                    className="slider-card__icon"
                    style={{ backgroundImage: `linear-gradient(135deg, ${iconColor.start}, ${iconColor.end})`}}
                    mode="app"
                    size={72}
                >
                    {React.cloneElement(icon, {fill: 'white', width: 40, height: 40})}
                </Avatar>
                <div className="slider-card__title">
                    {title}
                </div>
                <span className="slider-card__text">
                    {text}
                </span>
                <div className="slider-card__form">
                    {form &&
                    <FormLayout>
                        <FormLayoutGroup top="Благотворительный взнос, ₽">
                            <Input className={!isError ? '' : 'error'}
                                   type="text"
                                   value={inputAmount}
                                   min="1"
                                   onChange={(e) => {
                                       let str = e.target.value;

                                       if (!str.match(/\D+/) && str[0] !== '0') {
                                           setInputAmount(e.target.value);
                                           setIsError(false);
                                           return;
                                       }

                                       setIsError(false);
                                   }}
                                   onFocus={() => dispatch(hideNavigation(true))}
                                   onBlur={() => dispatch(hideNavigation(false))}
                                   placeholder="10 ₽"
                            />
                        </FormLayoutGroup>
                    </FormLayout>
                    }
                </div>
                <div className="slider-card__buttons">
                    {buttons.map(({text, handler}) => <Button size="l" key={text} onClick={handler}>{text}</Button>)}
                </div>
            </div>
        </Card>
    )
};

SliderCard.propTypes = {
    icon: PropTypes.any,
    title: PropTypes.any.isRequired,
    text: PropTypes.any.isRequired,
    buttons: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        handler: PropTypes.func
    }))
};

SliderCard.defaultProps = {
    buttons: [],
    form: null
};

export default SliderCard;



