import * as types from './actionTypes';

export const goPanel = ( nextPanel = null ) => {
    return {
        type: types.NAVIGATION_GO_PANEL,
        nextPanel,
    };
};

export const setModal = (modal = null) => {
    return {
        type: types.NAVIGATION_SET_MODAL,
        modal,
    };
};

export const setPopout = (popout = null) => {
    return {
        type: types.NAVIGATION_SET_POPOUT,
        popout,
    };
};

export const setProgressValue = (progressBarValue = null) => {
    return {
        type: types.NAVIGATION_SET_PROGRESS_VALUE,
        progressBarValue,
    }
};

export const hideNavigation = (isHide = false) => {
    return {
        type: types.NAVIGATION_HIDE,
        isHide
    }
};
