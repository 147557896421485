import bridge from '@vkontakte/vk-bridge';

const IS_DEV = window.location.href.indexOf("vk_app_id") === -1;

export const getProfile = () => new Promise (async (resolve) => {
    if (IS_DEV) {
        return resolve({
            "id": 2314852,
            "first_name": "Ирина",
            "last_name": "Денежкина",
            "sex": 1,
            "city": {
                "id": 2,
                "title": "Санкт-Петербург"
            },
            "country": {
                "id": 1,
                "title": "Россия"
            },
            "bdate": "10.4.1990",
            "photo_100": "https://pp.userapi.com/c836333/v836333553/5b138/2eWBOuj5A4g.jpg",
            "photo_200": "https://pp.userapi.com/c836333/v836333553/5b137/tEJNQNigU80.jpg",
            "timezone": 3
        });
    }

    const profile = await bridge.send('VKWebAppGetUserInfo', {});
    return resolve(profile);
});

export const getFriends = () => new Promise((resolve, reject) => {
    if (IS_DEV) {
        return resolve([]);
    }

    bridge.send('VKWebAppGetAuthToken', {
        app_id: window.vkapp_config && window.vkapp_config.vk_app_id,
        scope: 'friends'
    })
        .then(async (response) => {
            const friends = await bridge.send('VKWebAppCallAPIMethod', {
                method: 'friends.get',
                params: {
                    v: '5.103',
                    order: 'hints',
                    count: 5,
                    fields: 'photo_200',
                    access_token: response.access_token,
                }
            });

            return resolve(friends.response.items);
        })
        .catch(() => reject())
});

export const getLocation = () => new Promise((resolve, reject) => {
    if (IS_DEV) {
        return resolve([55.751574, 37.573856]);
    }

    bridge.send('VKWebAppGetGeodata', {})
        .then((response) => {
            if (!response.available) {
                return reject("not-available");
            }

            return resolve([Number(response.lat), Number(response.long)]);
        })
        .catch(() => reject("error"))
});

export const sendNotification = () => new Promise((resolve, reject) => {
    bridge.send('VKWebAppAllowNotifications', {})
        .then((response) => {
            if (response.result) {
                const data = new URLSearchParams();
                data.append('url', window.location.href);
                fetch("https://showreel.vkapps.pro/api/sendNotify", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: data,
                })
                    .then((response) => response.text())
                    .then((response) => {
                        if (response === "ok") {
                            resolve();
                        } else {
                            reject("server");
                        }
                    })
            } else {
                reject("client");
            }
        })
        .catch(() => reject("unknown"))
});

export const getPhone = () => new Promise((resolve, reject) => {
    if (IS_DEV) {
        return resolve("+79301186585");
    }

    bridge.send("VKWebAppGetPhoneNumber", {})
        .then((response) => {
            return resolve("+" + response.phone_number);
        })
        .catch(() => {
            return reject();
        })
});

export const openVKPay = (amount, merchant_id) => new Promise((resolve, reject) => {
    fetch(`https://showreel.vkapps.pro/api/generatePayPayload?amount=${amount}&merchant_id=${merchant_id}`)
        .then((response) => response.json())
        .then((response) => {
            if (response.status !== "ok") return reject();
            bridge.send("VKWebAppOpenPayForm", {
                app_id: window.vkapp_config && window.vkapp_config.vk_app_id,
                action: "pay-to-service",
                params: response.payload,
            });
        })
        .catch(() => reject())
});
