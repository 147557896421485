const PhoneNumberTransform = (phone) => {
    if (phone[0] === '+' || phone[1] === '7') {
        return `${phone.slice(0, 2)} ${phone.slice(2, 5)} ${phone.slice(5, 8)} ${phone.slice(8, 10)} ${phone.slice(10, 12)}`;
    }
    if (phone[0] === '8') {
        return `${phone.slice(0, 1)} ${phone.slice(1, 4)} ${phone.slice(4, 7)} ${phone.slice(7, 9)} ${phone.slice(9, 11)}`;
    }

    return phone;
};

export default PhoneNumberTransform;

