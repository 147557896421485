import React from 'react';
import PropTypes from 'prop-types';

import BasicContainer from '../../components/BasicContainer';
import { Card } from '@vkontakte/vkui';

import img from '../../assets/screen.jpg';
import img2x from '../../assets/screen@2x.jpg';
import img3x from '../../assets/screen@3x.jpg';

import './index.scss'


const QuickAccess = ({ id }) => {
    return (
        <BasicContainer
            id={id}
            title="Каталог"
            prevPanel="stats"
            buttons={[
                {
                    text: 'Что ещё интересного?',
                    nextPanel: 'user-features',
                }
            ]}
        >
            <div className="quick-access__text">
                Ваше приложение будет сразу доступно всем пользователям — каталог находится на второй вкладке приложения ВКонтакте
            </div>
            <Card className={'quick-access__content'} mode="shadow">
                <img src={img}
                     srcSet={`${img2x} 2x, ${img3x} 3x`}
                     alt=""/>
            </Card>
        </BasicContainer>
    );
};

QuickAccess.propTypes = {
    id: PropTypes.string.isRequired,
};

export default QuickAccess;
