import React from 'react';
import PropTypes from 'prop-types';
import FireEvent from '../../utils/FireEvent';
import bridge from '@vkontakte/vk-bridge';

import {Card, CardScroll} from '@vkontakte/vkui';
import BasicContainer from '../../components/BasicContainer';

import './index.scss';

const Introduction = ({ id }) => {

    const openApp = (appId) => {
        bridge.send("VKWebAppOpenApp", {"app_id": appId});
    };

    return (
        <BasicContainer

            id={id}
            title={<>Создать<br/> мини-приложение<br/> просто</>}
            prevPanel="flexibility"
            buttons={[
                {
                    text: 'Открыть сообщество',
                    handler: () => FireEvent("https://vk.com/vkappsdev"),
                    mode: 'secondary',
                }
            ]}
        >
            <p style={{ fontFamily: "var(--font-tt)", fontSize: 19, lineHeight: 1.26 }}>
                В нашем официальном сообществе<br/>
                собрана вся необходимая<br/>
                информация.<br/>
                Остались вопросы?<br/>
                Напишите нам сообщение!
            </p>
            <Card
                mode="shadow"
            >
                <div className="introduction__card-title">
                    Примеры<br/>
                    мини-приложений
                </div>
                <div className="introduction__card-apps">
                    <CardScroll>
                        <div className="introduction__card-app"
                             onClick={() => openApp(6739175)}
                        >
                            <img src='https://sun9-28.userapi.com/c849328/v849328433/b02ef/18a2fbUE-XU.jpg' alt=""/>
                            <span>
                                Программа<br/>
                                лояльности
                            </span>
                        </div>
                        <div className="introduction__card-app"
                             onClick={() => openApp(7252141)}
                        >
                            <img src='https://sun9-11.userapi.com/NBeCWue-hvq4Lp1zMBjAyRsWbhlt-O9zQ-3FAA/Kf7__ly-bCw.jpg' alt=""/>
                            <span>
                                Доставка<br/>
                                еды
                            </span>
                        </div>
                        <div className="introduction__card-app"
                             onClick={() => openApp(6681325)}
                        >
                            <img src='https://sun9-31.userapi.com/RC_QnEyWLSb0r9FDLc_v1kO7GDVUpzJy_vZ8WQ/ZtFiLJ0tPDA.jpg' alt=""/>
                            <span>
                                Заказ<br/>
                                такси
                            </span>
                        </div>
                        <div className="introduction__card-app"
                             onClick={() => openApp(6706576)}
                        >
                            <img src='https://sun9-68.userapi.com/c206820/v206820528/476c5/Sr5mQ7jjs8Q.jpg' alt=""/>
                            <span>
                                Покупки<br/>
                                онлайн
                            </span>
                        </div>
                    </CardScroll>
                </div>
            </Card>
        </BasicContainer>
    );
};

Introduction.propTypes = {
    id: PropTypes.string.isRequired,
};

export default Introduction;
